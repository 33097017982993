import { FlatsHeroDesktop } from 'components/FlatsHeroDesktop/FlatsHeroDesktop';
import { FlatsHeroMobile } from 'components/FlatsHeroMobile/FlatsHeroMobile';
import Layout from 'layout/index';
import React, { useMemo } from 'react';
import { FlatsList } from 'sections/FlatsList/FlatsList';
import { FlatsListMobile } from 'sections/FlatsListMobile/FlatsListMobile';
import { ImageMapType, SingleFlatType } from 'types/CommonTypes';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { getDisabledPages } from 'utils/utils';

interface FlatsPageProps {
    location: {
        pathname: string;
    };
    pageContext: any;
}

export default ({ pageContext, location }: FlatsPageProps) => {
    const isMobile = useBreakpoint('md');
    const dataTable = useMemo(() => {
        return pageContext?.data?.allWpInvestment?.edges?.map((item: any) => ({
            ...item.node.investmentFlat,
            buildingFloor: item.node.investmentFlat.buildingFloor.name,
            flatAmenities:
                item.node.investmentFlat.flatAmenities?.name ?? 'Brak',
            flatStatus: item.node.investmentFlat.flatStatus?.name,
            investmentBuilding:
                item.node.investmentFlat.investmentBuilding.name,
            priceMeter:
                item.node.investmentFlat.flatPrice &&
                item.node.investmentFlat.flatArea
                    ? Math.round(
                          item.node.investmentFlat.flatPrice /
                              item.node.investmentFlat.flatArea,
                      )
                    : null,
        }));
    }, [pageContext?.data?.allWpInvestment.edges]);

    const sortableData: SingleFlatType[] = dataTable?.filter(
        (item: SingleFlatType) => !item?.flatFeatured,
    );
    const noSortableData: SingleFlatType[] = dataTable?.filter(
        (item: SingleFlatType) => item?.flatFeatured,
    );
    const parsedDataTable = noSortableData?.concat(sortableData);

    const dataMap = useMemo(() => {
        return pageContext?.data?.wpInvestment?.investment?.searchByImage?.searchFloorByImage?.searchMap?.map(
            (item: ImageMapType) => ({
                availableCount: item?.availableCount,
                coords: item?.coordinates?.split(',')?.map((i) => Number(i)),
                href:
                    '/' +
                    pageContext?.slug +
                    '/mieszkania/' +
                    item?.buildingTax +
                    '/' +
                    item?.floorTax,
                id: item?.buildingTax + item?.floorTax,
                shape: 'poly',
                building: item?.buildingTax,
                floor: item?.floorTax,
            }),
        );
    }, [
        pageContext?.data?.wpInvestment?.investment?.searchByImage
            ?.searchFloorByImage?.searchMap,
    ]);

    const dataMapImage =
        pageContext?.data?.wpInvestment?.investment?.searchByImage
            ?.searchFloorByImage?.searchMapImage?.sourceUrl;
    const dataMapImageMediaDetails =
        pageContext?.data?.wpInvestment?.investment?.searchByImage
            ?.searchFloorByImage?.searchMapImage?.mediaDetails;

    return (
        <Layout
            displayPartnersPage={
                pageContext?.data?.wpInvestment?.investment?.enablePartnersPage
            }
            headerData={pageContext?.headerData}
            investmentSlug={pageContext?.slug}
            location={location}
            displayBanner
            investmentDisabledPages={getDisabledPages(
                pageContext?.data?.wpInvestment?.investment,
            )}
        >
            <>
                {!isMobile && (
                    <FlatsHeroDesktop
                        breadcrumbText={'mieszkania'}
                        id={pageContext?.data?.wpInvestment?.databaseId}
                        searchMap={dataMap}
                        searchMapImage={dataMapImage}
                        searchMapImageMediaDetails={dataMapImageMediaDetails}
                    />
                )}
                {!isMobile && dataTable && (
                    <FlatsList
                        data={parsedDataTable}
                        investmentSlug={pageContext?.slug}
                    />
                )}
                {isMobile && dataTable && (
                    <FlatsHeroMobile
                        data={parsedDataTable}
                        investmentSlug={pageContext?.slug}
                    />
                )}
                {isMobile && dataTable && (
                    <FlatsListMobile
                        flatsData={parsedDataTable}
                        investmentSlug={pageContext?.slug}
                    />
                )}
            </>
        </Layout>
    );
};
